<template>
  <div class="view view--products--healths">
    <div class="view-header">
      <div class="view-header__header">
        <Pagination />
        <Btn v-show="helperService.userHasPermission('produit_add')" class="action-btn" icon="plus" color="primary"
          text="Ajouter un produit" :to="{ name: 'seedAdd' }" />
      </div>
      <div class="view-header__footer">
        <h1 class="page-title">Liste des semences</h1>
      </div>
    </div>
    <div class="view-body">
      <Section class="section--list">
        <Search v-model="search" :searchEndpoint="list.api.endpoint" :apiParams="list.api.params"
          searchPlaceholder="Rechercher un produit de semence">
          <template v-slot:suggestions="{ item }">
            <router-link :to="{ name: 'seed', params: { id: item.id } }">
              {{ item.designation }}
            </router-link>
          </template>

          <SelectExtended id="culture_id" v-model="search.filters.culture.values" search label="Cultures" optionKey="id"
            optionValue="nom" apiEndpoint="culture" multiple :apiParams="{ limit: 0, sort: 'nom.ASC' }" />

          <SelectExtended v-model="search.filters.composant.values" id="composant" multiple search
            label="Substance active" optionKey="id" optionValue="nom" :apiParams="{
              sort: `nom.ASC`
            }" apiEndpoint="composant" />

          <SelectExtended v-model="search.filters.famille.values" id="famille_id" search label="Famille de produits"
            optionKey="id" optionValue="nom" apiEndpoint="dictionnaire/famille/produit" :apiParams="{
              sort: `nom.ASC`
            }" multiple />

          <SelectExtended v-model="search.filters.nom_commercial.values" id="nom_commercial" label="Fournisseurs"
            optionKey="id" optionValue="nom" apiEndpoint="fournisseur" :apiParams="{
              sort: `nom.ASC`
            }" multiple />

          <SelectExtended id="sous_thematique" v-model="search.filters.sous_thematique.values" label="Sous-themes"
            optionKey="id" optionValue="nom" apiEndpoint="dictionnaire/sous-thematique" :apiParams="{
              sort: `nom.ASC`
            }" multiple />

          <Radio v-model="search.filters.actif.values" id="actif" :items="[
            { label: 'oui', value: true },
            { label: 'non', value: false },
          ]" label="Actif" inputStyle="inline" />

        </Search>

        <List :api="list.api" :header="list.headers" :items="list.col" :item-key="list.key" :target="list.target"
          @send-data="getProductTypeId">
          <template v-slot:group-actions="{ selectedItems }">
            <Btn v-show="helperService.userHasPermission('produit_remove')" text="Supprimer" color="default"
              @click="openModal('deleteItems', selectedItems)" />
          </template>

          <template v-slot:checkbox="{ item }">
            <template v-if="$store.state.auth.user.data.entite.id !== item.entite.id">
              <Checkbox :disabled="true" :name="`list-item-${item.id}`" :id="`checkbox-${item.id}`"
                :items="[{ value: item }]" />
            </template>
          </template>

          <template v-slot:cultures="{ item }">
            <template v-if="item.cultures.length > 0">
              <div class="tags">
                <MiniTag v-for="culture in item.cultures" :key="culture.id" :id="culture.uid" :text="culture.nom" />
              </div>
            </template>
            <template v-else>
              <span></span>
            </template>
          </template>

          <template v-slot:composants="{ item }">
            <template v-if="item.composants.length > 0">
              <div class="tags">
                <MiniTag
                  v-for="composant in item.composants"
                  :key="composant.id"
                  :id="composant.id"
                  :text="composant.observation"
                  :title="composant.nom"
                />
              </div>
            </template>
            <template v-else>
              <span></span>
            </template>
          </template>

          <template v-slot:noms_commerciaux="{ item }">
            <template v-if="item.noms_commerciaux.length > 0">
              <div v-for="nom in item.noms_commerciaux" :key="nom.id">
                {{ nom.fournisseur.nom }}
              </div>
            </template>
            <template v-else>
              <span></span>
            </template>
          </template>

          <template v-slot:entite="{ item }">
            {{ item.entite.nom }}
          </template>

          <template v-slot:actif="{ item }">
            {{ item.actif ? 'Actif' : 'Inactif' }}
          </template>

          <template v-slot:actions="{ item }">
            <!-- <Btn icon="kefta-dots" default round grow/> -->
            <ToolDropdown>
              <MenuItem v-show="helperService.userHasPermission('produit_show')" :item-data="{
                label: 'Consulter',
                iconName: 'visibility',
                route: { name: 'seed', params: { id: item.id } },
              }" />
              <MenuItem v-show="helperService.userHasPermission('produit_edit')
                && $store.state.auth.user.data.entite.id === item.entite.id
                " :item-data="{
    label: 'Éditer',
    iconName: 'create',
    route: { name: 'seedEdit', params: { id: item.id } },
  }" />
              <MenuItem v-show="helperService.userHasPermission('produit_remove')
                && $store.state.auth.user.data.entite.id === item.entite.id
                " :item-data="{
    label: 'Supprimer',
    iconName: 'trash-alt',
  }" @click="openModal('deleteProduct', item)" />
              <MenuItem v-show="helperService.userHasPermission('log_list')" :item-data="{
                label: 'Voir les logs',
                iconName: 'clipboard-list',
                route: {
                  name: 'ressourceLogs',
                  params: {
                    ressource: 'produits',
                    ressourceid: item.id,
                    from: 'seeds',
                    title: `Consulter les logs du produit : ${item.nom}`,
                    filters: `ressource_id:eq(${item.id})|n|objet:eq(Produit)`
                  },
                },
              }" />
            </ToolDropdown>
          </template>
        </List>

        <!-- Modals modalDeleteUser-->
        <Modal title="Supprimer des produits&nbsp;?" :active="modal.deleteItems" :data="modalData"
          @modal-close="modal.deleteItems = false">
          <template v-slot:modal-body="{ data }">
            <p>
              Voulez vous vraiment supprimer
              {{ data.length }} produits&nbsp;?
            </p>
          </template>
          <template v-slot:modal-footer="{ data }">
            <Btn text="Annuler" @click="modal.deleteItems = false" />
            <Btn text="Supprimer" @click="deleteItems(data)" icon="trash-alt" color="primary" />
          </template>
        </Modal>

        <!-- Modals modalDisableUser-->
        <Modal title="Supprimer" :active="modal.deleteProduct" :data="modalData"
          @modal-close="modal.deleteProduct = false">
          <template v-slot:modal-body>
            <p>
              Voulez vous vraiment supprimer ce produit&nbsp;?
            </p>
          </template>
          <template v-slot:modal-footer>
            <Btn text="Annuler" @click="modal.deleteProduct = false" />
            <Btn text="Supprimer" @click="deleteProduct()" icon="user-times" color="primary" />
          </template>
        </Modal>
      </Section>
    </div>
  </div>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Pagination from '@/components/list/Pagination.vue'
import Search from '@/components/list/Search.vue'
import List from '@/components/list/List.vue'
import Btn from '@/components/base/Btn.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Radio from '@/components/form/Radio.vue'
import MenuItem from '@/components/layout/MenuItem.vue'
import Modal from '@/components/layout/Modal.vue'
import MiniTag from '@/components/base/MiniTag.vue'
import Checkbox from '@/components/form/Checkbox.vue'

export default {
  name: 'SeedsView',
  components: {
    MiniTag,
    List,
    Search,
    Pagination,
    Section,
    Btn,
    ToolDropdown,
    SelectExtended,
    Checkbox,
    Radio,
    MenuItem,
    Modal,
  },

  data() {
    return {
      form: {
        references: [],
      },
      type: {
        id: null,
      },
      search: {
        attributs: ['nom', 'nom_commercial.nom', 'nom_commercial.code_interne', 'code_cooperative', 'code_obtenteur', 'code_ctps'],
        filters: {
          culture: {
            type: 'object',
            key: 'id',
            values: [],
          },
          composant: {
            type: 'object',
            key: 'id',
            values: [],
          },
          famille: {
            type: 'object',
            key: 'id',
            values: [],
          },
          nom_commercial: {
            type: 'object',
            key: 'fournisseur.id',
            values: [],
          },
          famille_chimique: {
            type: 'object',
            key: 'id',
            values: [],
          },
          sous_thematique: {
            type: 'object',
            key: 'id',
            values: [],
          },
          actif: {
            type: 'boolean',
            key: null,
            values: null,
            label: 'Inactif',
          },
        },
      },
      list: {
        target: {
          route: {
            name: 'seed',
            paramsFromRoute: undefined,
            itemParams: { id: 'id' },
          },
        },
        api: {
          endpoint: 'produit',
          params: {
            sort: 'designation.ASC',
            page: null,
            limit: null,
            filters: 'type.uid:eq(SEMENCE)',
            distinct: 1,
          },
        },
        headers: [
          {
            label: 'Designation', dataField: 'designation', sorted: true, isLink: true,
          },
          { label: 'Cultures', hidden: 'mobile' },
          { label: 'Fournisseur', hidden: 'mobile' },
          { label: 'Substances actives', hidden: 'tablet' },
          { label: 'Origine', hidden: 'mobile' },
          { label: 'Statut', hidden: 'mobile', dataField: 'actif' },
        ],
        col: ['designation', 'cultures', 'noms_commerciaux', 'composants', 'entite', 'actif'],
        key: 'id',
      },
      modalData: {},
      modal: {
        deleteItems: false,
        deleteProduct: false,
      },
    }
  },

  async created() {
    this.form.references = await this.productService.getReferenceByUids('famillechimique')
    this.form.thematique = await this.productService.getThematique(this.type.uid)
  },

  methods: {
    getProductTypeId(event) {
      if (event.length) {
        this.type.id = event[0].type.id
      }
    },
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },

    async deleteItems(itemsToDelete) {
      this.emitter.emit('open-loader')

      await itemsToDelete.forEach((item) => {
        if (item.entite.id === this.$store.state.auth.user.data.entite.id) {
          this.fetchService.delete(`produit/${item.id}`).then(
            () => {
              this.emitter.emit('alert', {
                type: 'success',
                content: `Le produit ${item.designation} a été supprimé.`,
              })
              this.emitter.emit('list-refresh')
              this.emitter.emit('close-loader')
            },
            (responseError) => {
              this.emitter.emit('alert', {
                type: 'error',
                content: responseError.data,
              })

              this.emitter.emit('close-loader')
            },
          )
        }
      })

      this.modal.deleteItems = false
    },

    deleteProduct() {
      this.emitter.emit('open-loader')

      this.fetchService.delete(`produit/${this.modalData.id}`).then(
        () => {
          this.emitter.emit('alert', {
            type: 'success',
            content: 'Le produit a bien été supprimé.',
          })
          this.emitter.emit('list-refresh')
          this.emitter.emit('close-loader')
        },
        (responseError) => {
          this.emitter.emit('alert', {
            type: 'error',
            content: responseError.data,
          })
          this.emitter.emit('close-loader')
        },
      )

      this.modal.deleteProduct = false
    },
  },
}
</script>
